<template>
    <div class="p-5 flex justify-end w-full">
        <el-button size="medium" type="primary" @click="goToPageBefore">Tillbaka</el-button>
        <el-button size="medium" type="warning">PDF Utkast Årsredovisning</el-button>
        <el-button size="medium" class="button is-info" @click="showInspect">Granska</el-button>
        <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
    </div>
</template>

<script>
export default {
    methods: {
        goToPageBefore() {
            this.$emit("go-to-page-before");
        },
        showInspect() {
            this.$emit("show-inspect");
        },
        goToNextPage() {
            this.$emit("go-to-next-page");
        },
    },
};
</script>
